/**
*
*	Name:			IRANSansXFaNum Fonts
*	Version:			2.4
*	Author:			Moslem Ebrahimi (moslemebrahimi.com)
*	Created on:		Apr 22, 2021
*	Updated on:		Apr 22, 2021
*	Website:			http://fontiran.com
*	Copyright:		Commercial/Proprietary Software
--------------------------------------------------------------------------------------
فونتایران سنس Xا یک نرم افزار مالکیتی محسوب می شود. جهت آگاهی از قوانین استفاده از این فونت ها لطفا به وب سایت (فونت ایران دات کام) مراجعه نمایید
--------------------------------------------------------------------------------------
IRANSansXFaNum fonts are considered a proprietary software. To gain information about the laws regarding the use of these fonts, please visit www.fontiran.com 
--------------------------------------------------------------------------------------
This set of fonts are used in this project under the license: (.....)
------------------------------------------------------------------------------------- fonts/-
*	
**/


@font-face {
  font-family: IRANSansXFaNum;
  font-style: normal;
  font-weight: 100;
  src: url('fonts/woff/IRANSansXFaNum-Thin.woff') format('woff'),
    url('fonts/woff2/IRANSansXFaNum-Thin.woff2') format('woff2');
}

@font-face {
  font-family: IRANSansXFaNum;
  font-style: normal;
  font-weight: 200;
  src: url('fonts/woff/IRANSansXFaNum-UltraLight.woff') format('woff'),
    url('fonts/woff2/IRANSansXFaNum-UltraLight.woff2') format('woff2');
}

@font-face {
  font-family: IRANSansXFaNum;
  font-style: normal;
  font-weight: 300;
  src: url('fonts/woff/IRANSansXFaNum-Light.woff') format('woff'),
    url('fonts/woff2/IRANSansXFaNum-Light.woff2') format('woff2');
}

@font-face {
  font-family: IRANSansXFaNum;
  font-style: normal;
  font-weight: 500;
  src: url('fonts/woff/IRANSansXFaNum-Medium.woff') format('woff'),
    url('fonts/woff2/IRANSansXFaNum-Medium.woff2') format('woff2');
}

@font-face {
  font-family: IRANSansXFaNum;
  font-style: normal;
  font-weight: 600;
  src: url('fonts/woff/IRANSansXFaNum-DemiBold.woff') format('woff'),
    url('fonts/woff2/IRANSansXFaNum-DemiBold.woff2') format('woff2');
}

@font-face {
  font-family: IRANSansXFaNum;
  font-style: normal;
  font-weight: 800;
  src: url('fonts/woff/IRANSansXFaNum-ExtraBold.woff') format('woff'),
    url('fonts/woff2/IRANSansXFaNum-ExtraBold.woff2') format('woff2');
}

@font-face {
  font-family: IRANSansXFaNum;
  font-style: normal;
  font-weight: 900;
  src: url('fonts/woff/IRANSansXFaNum-Black.woff') format('woff'),
    url('fonts/woff2/IRANSansXFaNum-Black.woff2') format('woff2');
}

@font-face {
  font-family: IRANSansXFaNum;
  font-style: normal;
  font-weight: bold;
  src: url('fonts/woff/IRANSansXFaNum-Bold.woff') format('woff'),
    url('fonts/woff2/IRANSansXFaNum-Bold.woff2') format('woff2');
}

@font-face {
  font-family: IRANSansXFaNum;
  font-style: normal;
  font-weight: normal;
  src: url('fonts/woff/IRANSansXFaNum-Regular.woff') format('woff'),
    url('fonts/woff2/IRANSansXFaNum-Regular.woff2') format('woff2');
}