@import url(fontiran.css);
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: IRANSansXFaNum !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
:root {
  --cream: #f9e9be;
  --red: #9a2827;
  --green: #478620;
  --darkRed: #761e1d;
  --orange: #f6b131;
  --darkOrange: #d8971f;
  --darkGreen: #397117;
  --brown: #3d2501;
  --lightBrown: #745322;
  --gray: #d8d1ca;
  --lable: #6f6965;
  --placeholder: #c7bdb4;
  --inputStrokeLogin: #f1c677;
  --inputStroke: #e4dacc;
  --dashedStroke: #c7bdb4;
  --checkboxStroke: #c7bdb4;
  --inputBackground: #fffcf6;
  --backgroundListWrapper: #fdf8ed;
  --activeTabBackground: #fcf4df;
  --hoverTabBackground: #fbefce;
  --activeMenuBackgroundTop: rgba(225, 176, 31, 1);
  --activeMenuBackgroundBottom: rgba(199, 136, 23, 1);
  --hoverMenuBackgroundTop: rgba(225, 176, 31, 0.1);
  --hoverMenuBackgroundBottom: rgba(199, 136, 23, 0.1);
  --selectBackground: #c7bdb4;
  --SelectButtonBackground: #e4dacc;
  --branchBackground: #fcf7e7;
  --hoverBranchBackground: #fff8dd;
  --userBackground: #f6f3f0;
  --strokeTheme: #d3d3d3;
  --disabledCheckbox: #d0ccc5;
  --disabledColorFilter: #ba9e9e;
  --hoverBackgroundFilter: #f6ebeb;
  --hoverBackgroundCompanyRequest: #fff3d4;
  --backgroundDetailCompanyRequestTime: #fdf8ed;
  --backgroundBoxDetail: #fafafa;
  --dashedDetail: #e1dfdb;
  --disabledColor: #b2b0b0;
  --backgroundPrice: #e8f4e8;
  --backgroundStoke: #f6efe0;
  --backgroundToggleButton: #e4dacc;
  --backgroundRate: #f5efe1;
  --backgroundBoxRoysaAcceptResponseToCompanies: #f0fded;
  --backgroundBoxRoysaRejectResponseToCompanies: #fdeded;
  --activeCompanyRequestBackgroundStart: #feca31;
  --activeCompanyRequestBackgroundEnd: #f0b300;
  --hoverToggleButton: #f6f3ec;
  --hoverTr: #eceae9;
  --backgroundRate: #f9eddd;
}
.mapboxgl-map {
  width: 100% !important;
  height: 100% !important;
}
#root {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: var(--cream);
}
/* start Input */
input:focus {
  background: rgba(255, 255, 255, 0.4);
  color: black;
  outline: none;
}
input::-webkit-input-placeholder {
  /* Edge */
  color: var(--placeholder);
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--placeholder);
}
input::placeholder {
  color: var(--placeholder);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
/* end Input */

/* start Loading */
.loading {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  background-color: var(--cream);
  justify-content: center;
  align-items: center;
}
.loadingList {
  width: 100%;
  height: 81vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.spinner {
  position: relative;
  width: 16px;
  height: 16px;
}

.spinner::before,
.spinner::after {
  --radius: 250;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  animation: spinner-w7nm60 2.4s infinite linear;
  background: #c78817;
  border-radius: 50%;
}

.spinner::before {
  --radius: -250;
  background: #eeca5d;
}

@keyframes spinner-w7nm60 {
  0% {
    transform: scale(1) rotate(0deg) translateY(calc(var(--radius) * 1%));
  }

  50% {
    transform: scale(0.5) rotate(1440deg) translateY(0);
  }

  100% {
    transform: scale(1) rotate(2920deg) translateY(calc(var(--radius) * 1%));
  }
}
/* end Loading */

/* start Toast */
.toast-position {
  top: 4vh !important;
}
.Toastify__toast {
  direction: unset !important;
}
/* end Toast */

/* start Modal*/
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@-webkit-keyframes animatebottom {
  from {
    top: 0;
    opacity: 1;
  }
  to {
    top: -50px;
    opacity: 0;
  }
}

@keyframes animatebottom {
  from {
    left: 0;
    opacity: 1;
  }
  to {
    top: -50px;
    opacity: 0;
  }
}

.Modal {
  position: absolute;
  background-color: white;
  z-index: 22;
  border-radius: 1.4vw;
  border: none;
}
.Overlay {
  z-index: 21;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.3s;
  animation-name: animatetop;
  animation-duration: 0.3s;
}
.ReactModal__Overlay--before-close {
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 0.25s;
  animation-name: animatebottom;
  animation-duration: 0.25s;
}

/* end Modal*/

.colorPicker {
  min-width: 0 !important;
  background: var(--inputBackground) !important;
  border: 0.11vw solid var(--inputStroke) !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  direction: ltr !important;
  font-family: "Times New Roman", Times, serif !important;
}
.colorPicker > div:nth-child(1) {
  width: 100% !important;
  height: 30vh !important;
  border-radius: 1.4vh 1.4vh 0 0 !important;
  border: 0 !important;
  margin: 0 !important;
}
.colorPicker > div:nth-child(1) > div > div:nth-child(2) > div {
  height: 1vw !important;
  width: 1vw !important;
  border-radius: 50% !important;
  border: 0.2vw solid white !important;
}
.colorPicker > div:nth-child(2) {
  width: 100% !important;
  padding-inline: 0.6vw !important;
}

.colorPicker > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) {
  width: 2.4vw !important;
  height: 4vh !important;
  border-radius: 1vh !important;
}
.colorPicker > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) > div {
  border-radius: 1vh !important;
  border: 0.11vw solid var(--inputStroke) !important;
}
.colorPicker > div:nth-child(2) > div:nth-child(2) {
  margin-inline-start: 0.8vw !important;
}
.colorPicker > div:nth-child(2) > div:nth-child(2) > div {
  height: 1.2vh !important;
}
.colorPicker > div:nth-child(2) > div:nth-child(2) > div:nth-child(2) {
  margin-top: 1.2vh !important;
}
.colorPicker > div:nth-child(2) > div:nth-child(2) > div > div > div {
  height: 1.8vh !important;
  width: 1.8vh !important;
  border: 0.3vw solid white !important;
  transform: translate(-0.3vw, -0.3vh) !important;
}

.colorPicker > div {
  margin: 0.4vw !important;
}
.colorPicker > div > label > div {
  width: 4.7vw;
  background: white !important;
  border-radius: 1vh !important;
  border: 0.11vw solid var(--inputStroke) !important;
}
.colorPicker > div > label > div > span {
  font-size: 0.7vw !important;
  padding-left: 0.5vw !important;
}
.colorPicker > div > label > span {
  font-size: 0.6vw !important;
}
.colorPicker > div > div > label > div {
  width: 3.1vw;
  background: white !important;
  border-radius: 1vh !important;
  margin: 0.1vw !important;
  border: 0.11vw solid var(--inputStroke) !important;
}
.colorPicker input {
  text-align: center !important;
  font-size: 0.7vw !important;
  padding: 0 !important;
  padding-block: 0.6vh !important;
  width: 100% !important;
}
.colorPicker > div > div > label > span {
  font-size: 0.6vw !important;
}
